import $ from 'jquery';
import dateParser from '../i18n/dateParser';
import dateFormatter from '../i18n/dateFormatter';
import { activeFilterValues as activeFilterValuesGetter } from '../store/modules/dynamic/overview/getters';

$.fn.dataTable.ext.search.push(
    function dateRangeFilter(settings, data, dataIndex) {
        const dateRangeFilters = getRelevantFilters(settings);

        // Does this table have a daterange filter?
        if (!dateRangeFilters.length) {
            return true;
        }

        const activeFilterValues = activeFilterValuesGetter(settings.oInit.oCustomState);
        const activeDateRangeFilters = dateRangeFilters.filter(f => activeFilterValues[f.attribute] && activeFilterValues[f.attribute].length);

        // Get the DataTable instance so we can determine the indexes of the needed columns
        const dataTable = $(settings.nTable).DataTable();

        // Check all active daterange filters
        for (const filter of activeDateRangeFilters) {
            const columnIndex = dataTable.column(filter.attribute + ':name').index();

            // Parse the date
            const date = parseDate(data[columnIndex]);
            const filterDates = activeFilterValues[filter.attribute];
            const startDate = new Date(filterDates[0]);
            const endDate = new Date(filterDates[1]);

            // Compare the dates
            if ((startDate <= date && endDate >= date) === false) {
                return false;
            }
        }

        return true;
    }
);

function getRelevantFilters(settings, type = 'daterange') {
    if (!settings.oInit.oCustomSettings || !settings.oInit.oCustomState) {
        return [];
    }

    return settings.oInit.oCustomSettings.filters.filter(f => f.type === type);
}

function parseDate(date) {
    return dateParser(date.substring(0, 10) + 'T00:00:00');
}

export function appendFilterData(data, settings) {
    const dateRangeFilters = getRelevantFilters(settings);

    // Does this table have a daterange filter?
    if (!dateRangeFilters.length) {
        return true;
    }

    const activeFilterValues = activeFilterValuesGetter(settings.oInit.oCustomState);
    const activeDateRangeFilters = dateRangeFilters.filter(f => activeFilterValues[f.attribute] && activeFilterValues[f.attribute].length);

    // Add the values to the data
    for (const filter of activeDateRangeFilters) {
        for (const column of data.columns) {
            if (column.data !== filter.attribute) {
                continue;
            }

            column.search.value = activeFilterValues[filter.attribute]
                .map(date => dateFormatter(new Date(date)));
        }
    }
}
