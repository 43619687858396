import OverviewTableColumnRenderer from './OverviewTableColumnRenderer';

export default class OverviewTableSubTableRenderer {
    /**
     * @param {Array} columns
     * @param {Array} data
     *
     * @return {Element}
     */
    static render(columns, data) {
        const div = document.createElement('div');
        div.className = 'overview__table';

        const table = document.createElement('table');
        div.appendChild(table);
        table.className = 'table';

        table.appendChild(this.getTableHeader(columns));
        table.appendChild(this.getTableBody(columns, data));

        return div;
    }

    /**
     * @param {Array} columns
     *
     * @return {Element}
     */
    static getTableHeader(columns) {
        const header = document.createElement('thead');
        const tr = header.insertRow();

        columns.forEach((column) => {
            const th = document.createElement('th');
            tr.appendChild(th);

            if (column.options.width) {
                th.style = `width: ${column.options.width}${/^\d+$/.test(column.options.width) ? 'px' : ''};`;
            }

            const span = document.createElement('span');
            th.appendChild(span);
            span.className = 'table__th-wrapper';
            span.innerText = column.label;
        });

        return header;
    }

    /**
     * @param {Array} columns
     * @param {Array} data
     *
     * @return {Element}
     */
    static getTableBody(columns, data) {
        const body = document.createElement('tbody');

        data.forEach((row, index) => {
            const tr = body.insertRow();

            if (index % 2 === 1) {
                tr.className = 'even';
            } else {
                tr.className = 'odd';
            }

            columns.forEach((column) => {
                const td = document.createElement('td');
                tr.appendChild(td);
                td.innerHTML = OverviewTableColumnRenderer.render(column.type, row[column.data], column.options);
            });
        });

        return body;
    }
}
