import Vue from 'vue';
import S from 'string';
import i18n from '../i18n';
import iconRenderer from './iconRenderer';

export default class OverviewTableColumnRenderer {
    /**
     * @param {String} type
     * @param {*} data
     * @param {{}} [options]
     *
     * @returns {String}
     */
    static render(type, data, options = {}) {
        let functionName = S('render-' + type).camelize();

        if (typeof this[functionName] !== 'function') {
            functionName = 'renderDefault';
        }

        return this[functionName](data, options);
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderDefault(data, options = {}) {
        if (data === undefined || data === null || data === false) {
            return options.emptyLabel || '';
        }

        return S(data).escapeHTML().toString().replace(/(\r\n|\n\r|\r|\n)/g, '<br>$1');
    }

    /**
     * @param {*} data
     * @param {{}} [options]
     *
     * @returns {String}
     */
    static renderActive(data, options = {}) {
        if (data === true) {
            return `<span class="label label--success">${i18n.t('overview.active')}</span>`;
        } else {
            return `<span class="label label--inactive">${i18n.t('overview.inactive')}</span>`;
        }
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderCurrency(data, options = {}) {
        if (data === undefined || data === null || data === false) {
            return options.emptyLabel || '';
        }

        return i18n.c(data);
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderColor(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        return `<span class="overview__column-color" style="background-color:${data};" title="${data}"></span>`;
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderDate(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        return i18n.ds(data, 'date');
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderDatetime(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        return i18n.ds(data, 'datetime');
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderIcon(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        return iconRenderer(data);
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderIconTooltip(data, options = {}) {
        if (!data?.icon) {
            return options.emptyLabel || '';
        }

        const icon = iconRenderer(data.icon);

        if (!data.tooltip) {
            return icon;
        }

        const type = options.type || 'default';
        const placement = options.placement || 'left';

        const iconEl = `<span class="hint--rounded hint--${type} hint--${placement}" aria-label="${data.tooltip}">${icon}</span>`;

        if (!data.action) {
            return iconEl;
        }

        return `<a href="#" data-cell-action>${iconEl}</a>`;
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderInfo(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        return `<span aria-label="${data}" class="hint--left">${iconRenderer('info')}</span>`;
    }

    /**
     * @param {*} data
     * @param {{}} [options]
     *
     * @returns {String}
     */
    static renderRaw(data, options = {}) {
        return data;
    }

    /**
     * @param {*} data
     * @param {{}} [options]
     *
     * @returns {String}
     */
    static renderReadonly(data, options = {}) {
        if (data !== true) {
            return '';
        }

        return iconRenderer('lock');
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderStatus(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        return `<div class="overview-status" title="${data.title}">${iconRenderer(data.icon, 'overview-status__icon')}</div>`;
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderLabel(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        return `<div class="label label--${data.style}">${data.label}</div>`;
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderTime(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        return i18n.ds(data, 'time');
    }

    /**
     * @param {*} data
     * @param {{[emptyLabel]: String}} [options]
     *
     * @returns {String}
     */
    static renderVueComponent(data, options = {}) {
        if (!data) {
            return options.emptyLabel || '';
        }

        const outputWrapper = document.createElement('div');
        outputWrapper.appendChild(new Vue({ i18n, template: data }).$mount().$el);

        return outputWrapper.innerHTML;
    }
}
